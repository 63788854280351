<template>
  <div class="text-center px-4 py-10">
    <AppIcon size="50" class="opacity-50 mb-3" v-if="o.icon" :name="o.icon" />
    <div class="font-semibold" v-if="o.title">{{ o.title }}</div>
    <p
      v-if="o.description"
      v-html="o.description"
      class="md:max-w-420px m-auto"
    ></p>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { DataTableView } from "./types";
import { isEmpty } from "lodash";
import { isDefaultView } from "./utils";

export default defineComponent({
  props: {
    icon: { type: String },
    title: { type: String },
    description: { type: String },
    view: { type: Object as PropType<DataTableView> },
  },
  computed: {
    o() {
      const o = {
        icon: this.icon,
        title: this.title,
        description: this.description,
      };

      if (this.view) {
        // if this is the default view and options has values
        if (isDefaultView(this.view) && !isEmpty(filterUndefined(o))) {
          return o;
        }

        if (this.view?.custom) {
          if (!o.icon) {
            o.icon = "search";
          }
          o.description = "Try changing the filters or search term";
        }

        const v = filterUndefined({
          icon: this.view.icon,
          title: this.view.emptyTitle,
          description: this.view.emptyDescription || this.view.description,
        });
        Object.assign(o, v);
      }

      if (!o.title && !o.description) {
        o.description = "No data.";
      }

      return o;
    },
  },
});
</script>
