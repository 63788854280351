import {VAvatar, VIcon} from 'vuetify/components'
import {ImageField} from '#components'
import type { MediaFile } from "~/layers/admin/modules/media-library/runtime/types";

export default defineComponent({
  name: "ResourceAvatar",
  props: {
    image: { type: Object as PropType<MediaFile> },
    aspectRatio: { type: [String, Number], default: 1 },
    height: { type: [String, Number] },
    width: { type: [String, Number] },
    alt: { type: String },
  },
  render(){
    return (
      <VAvatar class="border" rounded="lg" tile>
        {
          this.image?
           <ImageField image={this.image} aspect-ratio={this.aspectRatio} height={this.height} width={this.width} alt={this.alt} /> :
           <VIcon icon="image" size="20" />
        }
      </VAvatar>
    )
  }
});
